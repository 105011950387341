import { GrClose } from "react-icons/gr";
import "../css/Overlay.css";
import { useEffect, useState } from "react";
import Loader from "./Loader";

const networkInfo = {
	DASH: {
		DASH: "2 confirmations",
	},
	DAI: {
		POLYGON: "1 block",
	},
	BTC: {
		BTC: "1 confirmation",
	},
	BCH: {
		BCH: "2 blocks",
	},
	TON: {
		TON: "1 block",
	},
	MATIC: {
		POLYGON: "1 block",
	},
	LTC: {
		LTC: "2 confirmations",
	},
	ETH: {
		ARBITRUM: "1 block",
		ERC20: "1 block",
	},
	USDT: {
		ERC20: "1 block",
		TRC20: "19 blocks",
		AVALANCHE: "1 block",
		BEP20: "5 blocks",
		POLYGON: "1 block",
	},
	XMR: {
		XMR: "10 blocks",
	},
	USDC: {
		AVALANCHE: "1 block",
	},
	VERSE: {
		ERC20: "1 block",
	},
};

export default function Overlay({
	setOverlayId,
	overlayId,
	isOverlayOpened,
	setIsOverlayOpened,
}) {
	const [overlayText, setOverlayText] = useState("");
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [offset, setOffset] = useState({ x: 0, y: 0 });

	const [tradeInfo, setTradeInfo] = useState({});
	const [infoLoading, setInfoLoading] = useState(false);

	const [requisites, setRequisites] = useState("");
	const [bank, setBank] = useState("");

	useEffect(() => {
		setOverlayText(overlayId);
		setIsOverlayOpened(overlayId !== "");
	}, [overlayId]);

	useEffect(() => {
		const centerX = window.innerWidth / 2;
		const centerY = window.innerHeight / 2;
		setPosition({ x: centerX, y: centerY });
	}, []);

	function getNetworkInfo(inputString) {
		const sanitizedString = inputString
			.toUpperCase()
			.replace(/[()>/]/g, " ")
			.replace(/\s+/g, " ")
			.trim();

		const words = sanitizedString.split(" ");
		const results = new Set();
		let currentCrypto = null;

		for (let word of words) {
			if (networkInfo[word]) {
				currentCrypto = word;
			}

			if (currentCrypto && networkInfo[currentCrypto][word]) {
				const result = `${currentCrypto} (${word}): ${networkInfo[currentCrypto][word]}`;
				results.add(result);
			}
		}

		return results.size > 0 ? Array.from(results).join("; ") : null;
	}
	const startDragging = (e) => {
		if (
			e.target.className === "ScrollContent" ||
			e.target.className === "Overlay"
		) {
			e.preventDefault();
			setDragging(true);
			setOffset({
				x: e.clientX - position.x,
				y: e.clientY - position.y,
			});
		}
	};

	const stopDragging = () => {
		setDragging(false);
	};

	const handleDragging = (event) => {
		if (!dragging) return;

		const overlayElement = document.querySelector(".Overlay");

		const { clientWidth, clientHeight } = overlayElement;
		const halfWidth = clientWidth / 2;
		const halfHeight = clientHeight / 2;

		const maxX = window.innerWidth - halfWidth;
		const maxY = window.innerHeight - halfHeight;

		const posX = event.clientX - offset.x;
		const posY = event.clientY - offset.y;

		setPosition({
			x: Math.max(halfWidth, Math.min(maxX, posX)),
			y: Math.max(halfHeight, Math.min(maxY, posY)),
		});
	};

	useEffect(() => {
		if (dragging) {
			document.addEventListener("mousemove", handleDragging);
			document.addEventListener("mouseup", stopDragging);
		} else {
			document.removeEventListener("mousemove", handleDragging);
			document.removeEventListener("mouseup", stopDragging);
		}
		return () => {
			document.removeEventListener("mousemove", handleDragging);
			document.removeEventListener("mouseup", stopDragging);
		};
	}, [dragging]);

	function forceSetInvalidRequisites() {
		if (overlayText === "") return;
		fetch("/api/chat/setInvalidRequisites", {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				exchangeId: overlayText,
			}),
		})
			.then(async (r) => {
				var rJson = await r.json();
				window.addPush(rJson.message);
				getTradeInfo();
			})
			.catch((error) =>
				console.error("Error while forceSetInvalidRequisites:", error)
			);
	}

	function forceCancelTrade() {
		if (overlayText === "") return;
		fetch("/api/chat/cancelTrade", {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				exchangeId: overlayText,
			}),
		})
			.then(async (r) => {
				var rJson = await r.json();
				window.addPush(rJson.message);
				getTradeInfo();
			})
			.catch((error) =>
				console.error("Error while forceSetInvalidRequisites:", error)
			);
	}

	function updateRequisites() {
		if (overlayText === "") return;
		fetch("/api/chat/changeRequisites", {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				exchangeId: overlayText,
				new_data: requisites,
				newbank: bank,
			}),
		})
			.then(async (r) => {
				var rJson = await r.json();
				window.addPush(rJson.message);
				getTradeInfo();
			})
			.catch((error) => console.error("Error while updateRequisites:", error));
	}

	function getTradeInfo() {
		if (overlayText === "") return false;
		setInfoLoading(true);
		fetch("/api/chat/getTradeInfo", {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				exchangeId: overlayText,
			}),
		})
			.then(async (r) => {
				var rJson = await r.json();
				console.log(r.status);
				if (r.status == 201) {
					setTradeInfo(rJson);
				} else {
					window.addPush(rJson.message);
				}
			})
			.catch((error) => console.error("Error while getTradeInfo:", error))
			.finally(() => {
				setInfoLoading(false);
			});
	}

	useEffect(() => {
		setTradeInfo({});
		var t = setTimeout(() => {
			getTradeInfo();
		}, 500);

		return () => {
			clearTimeout(t);
		};
	}, [overlayText]);

	if (!isOverlayOpened) return false;

	return (
		<>
			<div
				className="Overlay"
				style={{
					position: "absolute",
					top: `${position.y}px`,
					left: `${position.x}px`,
					cursor: dragging ? "grabbing" : "grab",
				}}
				onMouseDown={startDragging}
			>
				<div className="Head">
					<input
						value={overlayText}
						autoFocus={true}
						onChange={(e) => setOverlayText(e.target.value)}
						placeholder="ID обмена / хэш"
					/>
					<GrClose
						onClick={() => {
							setIsOverlayOpened(false);
							setOverlayId("");
						}}
						size={18}
						className="Close"
					/>
				</div>
				{infoLoading && <Loader style={{ height: "calc(100% - 20px)" }} />}
				<div className="ScrollContent">
					{tradeInfo.status && (
						<p>
							Статус: {tradeInfo.normalizedStatus} ({tradeInfo.status})
						</p>
					)}
					{tradeInfo.otcTradeStatus && (
						<p>Статус вывода рублей: {tradeInfo.otcTradeStatus}</p>
					)}
					{tradeInfo.direction && <p>Направление: {tradeInfo.direction}</p>}
					{tradeInfo.direction && <p style={{ fontSize: "12px" }}>{getNetworkInfo(tradeInfo.direction)}</p>}
					{tradeInfo.otcDirection && (
						<p>Направление вывода рублей: {tradeInfo.otcDirection}</p>
					)}
					{tradeInfo.sendAmount && (
						<p>Сумма отправления: {tradeInfo.sendAmount}</p>
					)}
					{tradeInfo.getAmount && <p>Сумма получения: {tradeInfo.getAmount}</p>}
					{tradeInfo.exchType && <p>Тип обмена: {tradeInfo.exchType}</p>}
					{tradeInfo.creationDate && (
						<p>Дата создания: {tradeInfo.creationDate}</p>
					)}
					{tradeInfo.receivedDate && (
						<p>Дата отправки: {tradeInfo.receivedDate}</p>
					)}
					{tradeInfo.sendCredentials && (
						<p>Адрес для оплаты: {tradeInfo.sendCredentials}</p>
					)}
					{tradeInfo.getCredentials && (
						<p>Адрес получения: {tradeInfo.getCredentials}</p>
					)}
					{tradeInfo.userPaidHash && tradeInfo.userPaidHash !== "None" && (
						<p>
							Хэш оплаты:{" "}
							<a target="_blank" href={tradeInfo.userPaidHash}>
								{tradeInfo.userPaidHash}
							</a>
						</p>
					)}
					{tradeInfo.ourHash && tradeInfo.ourHash !== "None" && (
						<p>
							Хэш отправки:{" "}
							<a target="_blank" href={tradeInfo.ourHash}>
								{tradeInfo.ourHash}
							</a>
						</p>
					)}
					<div className="Buttons">
						{tradeInfo.otcTradeStatus === "Ожидает выплаты банком" &&
							tradeInfo.status !== "3" &&
							tradeInfo.status !== "successfull" && (
								<button className="Button" onClick={forceSetInvalidRequisites}>
									Невалидные реквизиты
								</button>
							)}
						{tradeInfo.status &&
							!["3", "successfull", "4", "cancel"].includes(
								tradeInfo.status.toString()
							) && (
								<button className="Button" onClick={forceCancelTrade}>
									Отменить обмен
								</button>
							)}
						{tradeInfo.status &&
							tradeInfo.otcTradeStatus === "Невалидные реквизиты" && (
								<>
									<select
										className="Select"
										value={bank}
										onChange={(e) => setBank(e.target.value)}
									>
										<option disabled value="">
											Выберите новый банк
										</option>
										<option value="sbppsb">СБП ПСБ (Промсвязь)</option>
										<option value="sbpakbars">СБП Ак-Барс</option>
										<option value="sbprnkb">СБП РНКБ</option>
										<option value="sbpotp">СБП ОТП</option>
										<option value="sbpozon">СБП ОЗОН</option>
										<option value="sbpmtc">СБП МТС</option>
										<option value="sbppochtabank">СБП ПочтаБанк</option>
										<option value="sbpumoney">СБП Юмани</option>
										<option value="sbptinkoff">СБП Тинькофф</option>
										<option value="sbpsber">СБП Сбер</option>
										<option value="sbpraif">СБП Райфайзен</option>
										<option value="sbpalfa">СБП Альфабанк</option>
										<option value="sbpotkritie">СБП Открытие</option>
										<option value="sbpvtb">СБП ВТБ</option>
										<option value="sbpsovkombank">СБП Совкомбанк</option>
										<option value="sbpgazprom">СБП Газпром</option>
										<option value="sbprosbank">СБП Росбанк</option>
										<option value="sber">СБЕР (Карта)</option>
									</select>
									<input
										className="Input"
										value={requisites}
										onChange={(e) => setRequisites(e.target.value)}
										placeholder="Новые реквизиты"
									/>
									<button className="Button" onClick={updateRequisites}>
										Сохранить
									</button>
								</>
							)}
					</div>
				</div>
				<div className="Ellipse1"></div>
			</div>
		</>
	);
}
