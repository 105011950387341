import React, { useMemo } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const Attachments = React.memo(({ attach }) => {
	// Memoize video rendering specifically to prevent unnecessary updates
	const renderedContent = useMemo(() => {
		if (!attach || !attach.type) return null;

		const fileUrl = `/api${attach.file_url}.${attach.ext}`;

		switch (attach.type) {
			case "document":
				return attach.ext === "mp4" ? (
					<video
						src={fileUrl}
						
						loop
						controls
					/>
				) : (
					<a
						download={`document.${attach.ext}`}
						href={`${fileUrl}`}
					>
						Скачать файл
					</a>
				);

			case "audio":
				return <audio controls src={fileUrl} />;

			case "sticker":
				return attach.ext === "webm" ? (
					<video
						src={fileUrl}
						controls
						
						loop
					/>
				) : (
					<PhotoView key={fileUrl} src={fileUrl}>
						<img src={fileUrl} alt="Sticker" />
					</PhotoView>
				);

			case "video":
				return (
					<video
						src={fileUrl}
						controls
					/>
				);

			case "photo":
				return (
					<PhotoView key={fileUrl} src={fileUrl}>
						<img src={fileUrl} alt="Photo" />
					</PhotoView>
				);

			default:
				return null;
		}
	}, [attach]);

	if(renderedContent) return <div className="Attachments">{renderedContent}</div>;
});

export default Attachments;