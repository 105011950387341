import { useEffect } from "react";

export default function Push() {
	useEffect(() => {
		if ("Notification" in window && "serviceWorker" in navigator) {
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					console.log("Уведомления разрешены");
					subscribeUserToPush();
				}
			});

			navigator.serviceWorker
				.register("/service-worker.js")
				.then((registration) => {
					console.log("Service Worker зарегистрирован", registration);
				})
				.catch((error) => {
					console.error("Ошибка регистрации Service Worker", error);
				});
		}
	}, []);

	const subscribeUserToPush = async () => {
		try {
			const registration = await navigator.serviceWorker.ready;

			const pushSubscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey:
					"BH-SLAxWPyrWrUErbzWxr4VQYCKmSkokYtXLcpG3NqSJRPpOgzyOJ7b_b0MtYYGMniTpPTOHszSmFVxyHSxpxAs",
			});

			await fetch("/api/subscribe", {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(pushSubscription),
			});
		} catch {}
	};

	return null;
}
