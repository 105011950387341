import "../css/Info.css"

export default function Info() {
	return (
		<div className="Info">
			<img src="/logo.webp" />
			<h1>Lorem Ipsum</h1>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultricies
				velit nec varius cursus. Nam sed feugiat tellus, at lobortis orci. Etiam
				nulla purus, gravida at tortor vel, volutpat rutrum justo. Proin vitae
				nunc vitae nibh mattis maximus. Donec porta porttitor sem eget lacinia.
				Sed id volutpat lacus, id lobortis enim. Cras risus tortor, blandit a
				nulla vitae, commodo cursus neque. Vivamus vel urna ut nunc porttitor
				faucibus. Phasellus id lacus eu purus volutpat tincidunt at at felis.
			</p>
            <audio src="1.mp3" controls/>
		</div>
	);
}
