import { useRef, useEffect } from "react";

function Scrollable(props) {
	const scrollableRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (!scrollableRef.current) return;

			const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;

			if (scrollTop === 0) {
				console.log("Scrolled to bottom");
                if(props.messagesOnBottom === "3") return;
				props.onScrollBottom(props.messagesOnBottom, props.setMessagesOnBottom);
			}

			if (scrollTop + scrollHeight <= clientHeight+5) {
				console.log("Scrolled to top");
                if(props.messagesOnTop === "3") return;
				props.onScrollTop(props.messagesOnTop, props.setMessagesOnTop);
			}
		};

		const ref = scrollableRef.current;
		ref?.addEventListener("scroll", handleScroll);

		return () => ref?.removeEventListener("scroll", handleScroll);
	}, [props]);

	return (
		<div ref={scrollableRef} className="ScrollableList">
			{props.children}
		</div>
	);
}

export default Scrollable;
