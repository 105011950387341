import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { RiLoader2Fill } from "react-icons/ri";

const FileUploader = (props) => {
	const [isUploading, setIsUploading] = useState(false);

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		setIsUploading(true);
		const formData = new FormData();
		formData.append("file", file);
        formData.append("chatId", props.activeChat);

		try {
			const response = await fetch("/api/chat/sendFile", {
				method: "POST",
                 credentials: 'include',
				body: formData,
			});

			if (!response.ok) {
				throw new Error("File upload failed");
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsUploading(false);
		}
	};

	return (
		<>
			{isUploading ? (
				<RiLoader2Fill
					size={24}
					style={{ animation: "spin 3s ease-in-out infinite" }}
				/>
			) : (
				<>
					<GrAttachment
						size={24}
						onClick={() => document.getElementById("fileInput").click()}
						style={{ cursor: "pointer" }}
					/>
					<input
						type="file"
						id="fileInput"
						style={{ display: "none" }}
						onChange={handleFileChange}
					/>
				</>
			)}
		</>
	);
};

export default FileUploader;
