import "../css/TemplateWindow.css";

const template = [
	"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proinultricies velit nec varius cursus.",
	"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proinultricies velit nec varius cursus.",
	"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proinultricies velit nec varius cursus.",
];

export default function TemplateWindow({
	isTemplateWindowOpened,
	setIsTemplateWindowOpened,
    setText
}) {

    function processTemplate(key) {
        setIsTemplateWindowOpened(false);
        setText(template[key]);
    }

	if (!isTemplateWindowOpened) return null;
	return (
		<div className="Template">
			<div className="TemplatesList">
				{template.map((x, key) => (
					<div onClick={() => { processTemplate(key) }} className="TemplateItem">
						<p>{x}</p>
					</div>
				))}
			</div>
			<div className="Ellipse1"></div>
		</div>
	);
}
